import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="September 2022" />
		<NewsHeader />
		<h2>September 2022</h2>

		<h3 className="blogdate">
			Friday, September 9, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">Fans, by Kings of Leon, mis/reinterpreted</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				Yes, I may have actually misinterpreted this rock anthem by Kings of Leon...
				but I had a lot of fun doing so!
				So I think of it as a reinterpretation 😎.
				This new single was released a week ago while I was finishing up my trip to Canada;
				sorry for the late news 😉.
			</p>
			<p>
				You can check it wherever you prefer to stream or buy digital music.
				You might do that while also reading about it at my  <Link to="/music/12/">music page</Link>.
			</p>

			<div className="main--centerwrapper p">
				<StaticImage
					src="../../images/catalog artwork/JRIVEST12 fans front.jpg"
					alt="Jesse Rivest - Fans - cover art"
					placeholder="blurred"
					layout="constrained"
					className="main--centered"
					imgClassName="img--bordered-small"
					width={300}
				/>
			</div>

		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
